import { Grid } from 'theme-ui';
import ImgLoader from '~/components/Generic/ImgLoader';
import PressCardBody from './Body';

const FeaturedArticleCard = ({ article }) => {
  const { articleURL, image, title, pressLogo, description } = article;
  const linkAttributes = {
    target: '_blank',
    rel: 'noreferrer noopener',
    href: articleURL,
  };
  return (
    <Grid
      sx={{
        py: ['4.8rem', '9.6rem'],
        mx: ['-2.4rem', '-4rem'],
        px: ['2.4rem', '4rem'],
        backgroundColor: 'cardGrey',
        gridTemplateColumns: ['1fr', 'repeat(3, 1fr)'],
        placeItems: 'center',
        gap: ['1.6rem', '4rem'],
        mb: ['4.8rem', '6.4rem'],
      }}
    >
      <a {...linkAttributes} sx={{ gridColumn: 'span 2', width: '100%' }}>
        <ImgLoader
          image={image}
          forwardSx={{
            borderRadius: '1.6rem',
            aspectRatio: '16/9',
            objectFit: 'cover',
            width: '100%',
          }}
        />
      </a>
      <Grid as="a" {...linkAttributes}>
        <PressCardBody pressLogo={pressLogo} title={title} description={description} />
      </Grid>
    </Grid>
  );
};

export default FeaturedArticleCard;
