import React from 'react';
import { Box, Container, Text } from 'theme-ui';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

const CustomHero = React.memo(function CustomHero({ hero, forwardSx = {} }) {
  const { titleStyle, body, image, subtitle, color = 'transparent', backgroundColor, alternateMode } = hero;

  return (
    <Container
      variant="fullwidth"
      sx={{
        position: 'relative',

        height: [subtitle ? '19.6rem' : '14.4rem', subtitle ? '25rem' : '12rem'],

        color: color ?? 'white',
        backgroundColor: backgroundColor,
        height: ['8.4rem', '12rem'],
        'h2, h1': {
          fontSize: ['2rem', '4rem'],
          lineHeight: '1em',
          width: '100%',
        },
        p: {
          fontSize: '1.5rem',
          lineHeight: 1.25,
          width: ['30rem', null, '53rem'],
        },

        ...(alternateMode && {
          height: '100%',
          width: '100%',
          mx: '0',
          mt: ['calc(12.0rem - var(--nav-height))', 'calc(18.4rem - var(--nav-height))'],
          'h2, h1': {
            mt: 0,
            fontWeight: 'medium',
            fontSize: ['3.6rem', '5.6rem'],
            lineHeight: alternateMode && ['3.24rem', '5.04rem !important'],
            letterSpacing: alternateMode && '-0.03em',
            textAlign: alternateMode && 'center',
            mb: ['.8rem', '1.6rem'],
          },
          p: {
            textAlign: alternateMode && 'center',
            fontSize: ['1.4rem', '1.6rem'],
            lineHeight: ['1.61rem', '1.84rem'],
            letterSpacing: '-0.02em',
          },
        }),

        ...forwardSx,
      }}
    >
      {image && (
        <GatsbyImage
          image={getImage(image)}
          alt={image.title}
          sx={{
            filter: 'darker',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            zIndex: 0,
            width: '100%',
            height: '100%',
          }}
        />
      )}
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          mx: ['3rem', null, '8rem'],
          transform: 'translateY(-50%)',
          ...(alternateMode && {
            position: 'relative',
            transform: 'none',
            width: '100%',
            left: '0%',
            top: '0',
            mx: '0',
          }),
        }}
      >
        {titleStyle && (
          <Box
            sx={{ 'h2, h1': { lineHeight: ['1em', '3.4rem'] }, br: { display: ['unset', 'none'] } }}
            dangerouslySetInnerHTML={{ __html: titleStyle.childMarkdownRemark.html }}
          />
        )}
        {subtitle && <Text as="p">{subtitle}</Text>}
        {body && (
          <Box
            sx={{ a: { textDecoration: 'underline' } }}
            dangerouslySetInnerHTML={{ __html: body.childMarkdownRemark?.html }}
          />
        )}
      </Box>
    </Container>
  );
});

export default CustomHero;
