import { graphql } from 'gatsby';
import Layout from '~/components/Layout';
import Metadata from '~/components/Metadata';
import PressPage from '~/components/Press/page';

export default function Press({ data, pageContext }) {
  const { page, featuredArticle, articles } = data;
  const { numberOfPages, currentPage } = pageContext;

  return (
    <Layout transparentNav={page.transparentNav} navColor={page.navColor}>
      <Metadata title={page.name} />
      <PressPage
        page={page}
        articles={articles.nodes}
        featuredArticle={featuredArticle}
        numberOfPages={numberOfPages}
        currentPage={currentPage}
      />
    </Layout>
  );
}

export const query = graphql`
  query PressListPages($locale: String, $skip: Int!, $limit: Int!, $featuredArticleSlug: String) {
    page: contentfulPage(slug: { eq: "press" }, node_locale: { eq: $locale }) {
      ...ContentPageFragment
    }
    featuredArticle: contentfulArticle(slug: { eq: $featuredArticleSlug }) {
      ...ArticleCardFragment
    }
    articles: allContentfulArticle(
      limit: $limit
      skip: $skip
      filter: {
        article_gallery: { elemMatch: { slug: { eq: "press" } } }
        article: { elemMatch: { slug: { ne: $featuredArticleSlug } } }
      }
    ) {
      nodes {
        ...ArticleCardFragment
      }
    }
  }
`;
