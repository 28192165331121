import { Grid, Heading, Box } from 'theme-ui';
import Categories from '../Categories';
import { Link } from 'gatsby';

const BlogCardBody = ({ categories, title, description, fowardSx = {}, href, location }) => {
  return (
    <Grid
      sx={{
        gridTemplateRows: '2.8rem repeat(2, min-content)',
        gap: '1.6rem',
        width: '100%',
        ...fowardSx,
      }}
    >
      <Categories categories={categories} />
      <Link to={href} state={{ prevPath: location?.pathname }}>
        <Heading
          as="h4"
          sx={{
            fontSize: ['1.8rem', null, '2.4rem'],
            lineHeight: 1,
            letterSpacing: '-0.02em',
            fontWeight: 'medium',
            mb: '1.6rem',
          }}
        >
          {title}
        </Heading>
        {description && (
          <Box
            sx={{
              fontSize: ['1.4rem', null, '1.6rem'],
              lineHeight: 1.15,
              letterSpacing: '-0.01em',
              p: { my: 0 },
            }}
            dangerouslySetInnerHTML={{ __html: description.childMarkdownRemark.html }}
          />
        )}
      </Link>
    </Grid>
  );
};

export default BlogCardBody;
