import PropTypes from 'prop-types';
import PressCard from './Press';
import BlogCard from './Blog';
const ArticleCard = ({ article, location }) => {
  const { type } = article;

  if (type === 'Press') {
    return <PressCard article={article} />;
  }

  return <BlogCard article={article} location={location} />;
};

ArticleCard.propTypes = {
  article: PropTypes.shape({
    title: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    body: PropTypes.shape({}),
    description: PropTypes.shape({}),
    image: PropTypes.shape({
      title: PropTypes.string,
      url: PropTypes.string,
    }),
  }),
};

export default ArticleCard;
