import { Grid } from 'theme-ui';
import { Link } from 'gatsby';
import ImgLoader from '~/components/Generic/ImgLoader';

import BlogCardBody from './Body';

const FeaturedArticleCard = ({ article, location }) => {
  const { slug, image, categories, title, description } = article;
  const href = `/blog/article/${slug}/`;
  return (
    <Grid
      sx={{
        gridTemplateColumns: ['1fr', 'repeat(3, 1fr)'],
        placeItems: 'center',
        gap: ['1.6rem', '4rem'],
        mb: ['4.8rem', '6.4rem'],
      }}
    >
      <Link to={href} state={{ prevPath: location?.pathname }} sx={{ gridColumn: 'span 2', width: '100%' }}>
        <ImgLoader
          image={image}
          forwardSx={{
            borderRadius: '1.6rem',
            aspectRatio: '16/9',
            objectFit: 'cover',
            width: '100%',
          }}
        />
      </Link>
      <BlogCardBody categories={categories} title={title} description={description} href={href} />
    </Grid>
  );
};

export default FeaturedArticleCard;
