import { Flex, Box } from 'theme-ui';
import ImgLoader from '~/components/Generic/ImgLoader';
import PressCardBody from './Body';

const PressCard = ({ article, isFeatured }) => {
  const { articleURL, pressLogo, title, image } = article;

  return (
    <Flex
      as="a"
      sx={{ gap: '1.6rem', flexDirection: 'column' }}
      rel="noopener noreferrer"
      href={articleURL}
      target="_blank"
    >
      <ImgLoader
        image={image}
        forwardSx={{
          borderRadius: '1.6rem',
          aspectRatio: '16/9',
          objectFit: 'cover',
          width: '100%',
        }}
      />
      <PressCardBody pressLogo={pressLogo} title={title} />
      {/* Empty div to accomodate titles that take two lines. 
          Negative margin to compensate for the additional gap.          
      */}
      <Box sx={{ flex: '0 0 auto', mb: '-1.6rem' }} />
    </Flex>
  );
};

export default PressCard;
