import { Box } from 'theme-ui';

const CategoryTag = ({ category }) => {
  return (
    <Box
      as="span"
      sx={{
        color: 'white',
        background: 'black',
        p: '.8rem',
        borderRadius: '.4rem',
        width: 'fit-content',
        fontSize: '1.4rem',
        lineHeight: 0.8,
        letterSpacing: '-0.01em',
      }}
    >
      {category}
    </Box>
  );
};

export default CategoryTag;
