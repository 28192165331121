import { Box, Grid, Heading } from 'theme-ui';
import ArticleCard from '~/components/Article/Card';

export default function DefaultArticleList({ articles, articleListTitle, location }) {
  return (
    <Box>
      {articleListTitle && (
        <Heading
          sx={{
            mb: ['1.6rem', '2.4rem '],
            fontSize: ['2.8rem', '3.6rem'],
            textTransform: 'uppercase',
            fontWeight: 'medium',
            lineHeight: 0.9,
            letterSpacing: '-0.03em',
          }}
        >
          {articleListTitle}
        </Heading>
      )}
      <Grid
        sx={{
          gridTemplateColumns: ['1fr', 'repeat(2, 1fr)', 'repeat(3, 1fr)'],
          rowGap: ['4.8rem', '6.4rem '],
          columnGap: '2.5rem',
        }}
      >
        {articles.map((article) => (
          <ArticleCard key={article.id} article={article} location={location} />
        ))}
      </Grid>
    </Box>
  );
}
