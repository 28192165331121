import { Flex, Box } from 'theme-ui';
import EmailIcon from '~/assets/images/icons/email.svg';

const ContactDescription = ({ contactDescription }) => {
  return (
    <Flex sx={{ flexDirection: 'column', alignItems: 'center' }}>
      <Box
        sx={{
          p: { my: 0, textAlign: 'center' },
          mb: '1.6rem',
          fontSize: ['1.4rem', '1.6rem'],
          fontWeight: 'medium',
          lineHeight: 1.15,
        }}
        dangerouslySetInnerHTML={{ __html: contactDescription.childMarkdownRemark.html }}
      />
      <a href="mailto:media@omorpho.com">
        <EmailIcon />
      </a>
    </Flex>
  );
};

export default ContactDescription;
