import FeaturedPressCard from './Press/Featured';
import FeaturedBlogCard from './Blog/Featured';
const FeaturedArticleCard = ({ article }) => {
  if (!article) return null;
  const { type } = article;

  if (type === 'Press') {
    return <FeaturedPressCard article={article} />;
  }

  return <FeaturedBlogCard article={article} />;
};

export default FeaturedArticleCard;
