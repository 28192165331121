import React from 'react';
import { Grid } from 'theme-ui';
import ContentFeatureHero from '~/components/Content/Page/ContentFeatureHero';
import ArticleList from '~/components/Article/List';
import ContactDescription from './ContactDescription';
import Pagination from './Pagination';
import FeaturedArticleCard from '~/components/Article/Card/Featured';

const PressPage = ({ page, articles, featuredArticle, numberOfPages, currentPage }) => {
  const isFirstPage = currentPage === 1;

  const { hero, backgroundColor, sections } = page;

  const contactDescription = sections.find(
    (section) => section.slug === 'press-partnership-opportunities'
  )?.description;

  return (
    <Grid sx={{ py: ['4.8rem', '9.6rem'], px: ['2.4rem', '4rem'], background: backgroundColor ?? 'transparent' }}>
      <Grid
        sx={{
          maxWidth: 'var(--max-width)',
          mx: 'auto',
        }}
      >
        <ContentFeatureHero hero={hero} />
        {isFirstPage && <FeaturedArticleCard article={featuredArticle} />}
        <ArticleList articles={articles} />
        <Pagination numberOfPages={numberOfPages} currentPage={currentPage} />
        {contactDescription && <ContactDescription contactDescription={contactDescription} />}
      </Grid>
    </Grid>
  );
};

export default PressPage;
