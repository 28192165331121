import { Box, Grid } from 'theme-ui';
import ArticleCard from '~/components/Article/Card';
import LinkRenderer from '~/components/Generic/LinkRenderer';
import useCTAStyle from '~/hooks/components/use-cta-style';
import { getCategoryFromPath } from '~/utils/blog-category/get-category-from-path';

export default function ArticleListByCategory({ articlesByCategory, location }) {
  const buttonLabel = 'View more';
  const ctaStyle = useCTAStyle({ baseColor: 'Black', size: 'Medium', variant: 'Filled' });

  return (
    <Box>
      {articlesByCategory.map((category) => {
        const hasViewMoreButton = category.articles.length > 3;
        const href = `/blog/category/${category.title.toLowerCase()}/`;
        const title = `${getCategoryFromPath({ path: href })} category`;
        return (
          <Grid
            key={category.title}
            sx={{
              '--title-py': ['1.2rem', '1.6rem '],
              mb: ['calc(4.8rem - var(--title-py))', 'calc(6.4rem - var(--title-py))'],
            }}
          >
            <LinkRenderer
              title={title}
              innerLink={href}
              sx={{
                fontSize: ['2.8rem', '3.6rem'],
                textTransform: 'uppercase',
                fontWeight: 'medium',
                lineHeight: 0.9,
                letterSpacing: '-0.03em',
                backgroundColor: 'backgroundGrey',
                zIndex: 1,
                py: 'var(--title-py)',
              }}
            >
              {category.title}
            </LinkRenderer>
            <Grid
              sx={{
                gridTemplateColumns: ['1fr', 'repeat(2, 1fr)', 'repeat(3, 1fr)'],
                rowGap: ['4.8rem', '6.4rem '],
                columnGap: '2.5rem',
              }}
            >
              {category.articles
                .filter((_, index) => index < 3)
                .map((article, idx) => (
                  <ArticleCard key={article.id + idx} article={article} location={location} />
                ))}
            </Grid>
            {hasViewMoreButton && (
              <LinkRenderer
                to={href}
                title={title}
                sx={{
                  ...ctaStyle,
                  justifySelf: 'center',
                }}
                baseColor="Black"
                size="Medium"
                variant="Filled"
              >
                {buttonLabel}
              </LinkRenderer>
            )}
          </Grid>
        );
      })}
    </Box>
  );
}
