import DefaultArticleList from './Default';
import ArticleListByCategory from './ByCategory';
export default function ArticleList({ articlesByCategory, articles, location }) {
  if (articles && articles?.length > 0) return <DefaultArticleList articles={articles} location={location} />;

  if (articlesByCategory && articlesByCategory?.length > 0)
    return <ArticleListByCategory articlesByCategory={articlesByCategory} location={location} />;

  return null;
}
