import { Flex } from 'theme-ui';
import CategoryTag from './CategoryTag';

const Categories = ({ categories }) => {
  if (!categories || categories?.length < 1) return null;

  return (
    <Flex sx={{ alignItems: 'center', gap: '0.8rem', maxHeight: '2.8rem' }}>
      {categories.map((category) => (
        <CategoryTag key={category} category={category} />
      ))}
    </Flex>
  );
};

export default Categories;
