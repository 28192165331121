import { Link } from 'gatsby';

import { Grid } from 'theme-ui';
import ImgLoader from '~/components/Generic/ImgLoader';
import BlogCardBody from './Body';

const BlogCard = ({ article, location }) => {
  const { slug, image, categories, title, description } = article;
  const href = `/blog/article/${slug}/`;

  return (
    <Grid
      sx={{
        gridTemplateColumns: '1fr',
        gridTemplateRows: 'min-content 1fr',
      }}
    >
      <Link to={href} state={{ prevPath: location?.pathname }}>
        <ImgLoader
          image={image}
          forwardSx={{
            borderRadius: '1.6rem',
            aspectRatio: '16/9',
            objectFit: 'cover',
            width: '100%',
          }}
        />
      </Link>
      <BlogCardBody categories={categories} title={title} description={description} href={href} location={location} />
    </Grid>
  );
};

export default BlogCard;
