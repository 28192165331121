/**
 * Get the category from the  path
 * This assumes that the path is either the root ('/blog/') or a category page
 * Bonus: it can capitalize the first letter if asked to
 */

export const getCategoryFromPath = ({ path, capitalize = false }) => {
  if (!path || path === '/') return 'blog';
  const pathParts = path.split('/');
  let category = pathParts.filter(Boolean).pop();
  if (!category) category = 'blog';
  if (capitalize) return category.charAt(0).toUpperCase() + category.slice(1);
  return category;
};
