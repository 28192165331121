import { Box } from 'theme-ui';

import CoverFeature from '~/components/Generic/CoverFeature';
import CustomHero from '../Hero/Custom';
import React, { Fragment } from 'react';

const ContentFeatureHero = React.forwardRef(function ContentFeatureHero(
  { hero, mobileHero, transparentNav, ...props },
  ref
) {
  const { mobileCoverTextRef, desktopCoverTextRef } = ref?.current || {};
  // this convoluted code, because js solutions are blocking and create performance issues.
  return (
    (hero || mobileHero) && (
      <Fragment>
        {/* hero feature */}
        {hero && (
          <Box
            sx={{
              display:
                hero?.__typename === 'ContentfulHeroFeature' ? [mobileHero ? 'none' : 'block', null, 'block'] : 'none',
            }}
          >
            <CustomHero hero={hero} {...props} primary />
          </Box>
        )}
        {mobileHero && (
          <Box sx={{ display: [mobileHero?.__typename === 'ContentfulHeroFeature' ? 'block' : 'none', null, 'none'] }}>
            <CustomHero hero={mobileHero} {...props} primary />
          </Box>
        )}

        {/* cover feature */}
        {hero && (
          <Box
            sx={{
              display:
                hero?.__typename === 'ContentfulCoverFeature' ? [mobileHero ? 'none' : 'block', null, 'block'] : 'none',
            }}
          >
            <CoverFeature
              coverFeature={hero}
              isAtTop={true}
              {...props}
              ref={desktopCoverTextRef}
              transparentNav={transparentNav}
            />
          </Box>
        )}

        {mobileHero && (
          <Box
            sx={{
              display: [mobileHero?.__typename === 'ContentfulCoverFeature' ? 'block' : 'none', null, 'none'],
            }}
          >
            <CoverFeature
              coverFeature={mobileHero}
              isAtTop={true}
              {...props}
              ref={mobileCoverTextRef}
              transparentNav={transparentNav}
            />
          </Box>
        )}
      </Fragment>
    )
  );
});

export default ContentFeatureHero;
