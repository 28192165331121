import { Fragment } from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Heading, Box } from 'theme-ui';

const PressCardBody = ({ pressLogo, title, description }) => {
  return (
    <Fragment>
      <GatsbyImage
        image={getImage(pressLogo)}
        alt={pressLogo?.altText}
        sx={{
          zIndex: 0,
          maxHeight: '2.8rem',
        }}
        objectPosition="left center"
        objectFit="contain"
      />
      <Heading
        as="h4"
        sx={{
          fontSize: ['1.8rem', null, '2.4rem'],
          lineHeight: 1,
          letterSpacing: '-0.02em',
          fontWeight: 'medium',
        }}
      >
        {title}
      </Heading>
      {description && (
        <Box
          sx={{
            fontSize: ['1.4rem', null, '1.6rem'],
            lineHeight: 1.15,
            letterSpacing: '-0.01em',
            p: { my: 0 },
          }}
          dangerouslySetInnerHTML={{ __html: description.childMarkdownRemark.html }}
        />
      )}
    </Fragment>
  );
};

export default PressCardBody;
