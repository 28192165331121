import { Flex, Box } from 'theme-ui';
import { Link } from 'gatsby';
import ArrowLeft from '~/assets/images/icons/arrow-left.svg';
import ArrowRight from '~/assets/images/icons/arrow-right.svg';

const Pagination = ({ numberOfPages, currentPage }) => {
  if (numberOfPages === 1) return <Box sx={{ mt: ['6.4rem', '12.8rem'] }} />;

  const pageList = Array.from({ length: numberOfPages }, (_, i) => {
    const pageNumber = i + 1;
    return {
      number: pageNumber.toString().padStart(2, '0'),
      isActive: pageNumber === currentPage,
      slug: pageNumber === 1 ? '/press' : `/press/${pageNumber}`,
    };
  });

  const isFirstPage = currentPage === 1;
  const isLastPage = currentPage === numberOfPages;

  const getPreviousPageSlug = () => {
    const previousPage = currentPage - 1;
    if (isFirstPage) return '';
    if (previousPage === 1) return '/press';
    return `/press/${previousPage}`;
  };

  const getNextPageSlug = () => {
    const nextPage = currentPage + 1;
    if (isLastPage) return '';
    return `/press/${nextPage}`;
  };

  const svgSx = {
    height: '1.6rem',
  };

  return (
    <Flex
      sx={{
        justifySelf: 'center',
        gap: '2.4rem',
        alignItems: 'center',
        my: ['6.4rem', '12.8rem'],
      }}
    >
      <Link aria-label="Go to previous page" aria-disabled={isFirstPage} to={getPreviousPageSlug()}>
        <ArrowLeft sx={{ ...svgSx }} />
      </Link>
      {pageList.map((page) => (
        <Link key={page.slug} to={page.slug} sx={{ fontWeight: page.isActive ? 700 : 500 }}>
          {page.number}
        </Link>
      ))}
      <Link aria-label="Go to next page" aria-disabled={isLastPage} to={getNextPageSlug()}>
        <ArrowRight sx={{ ...svgSx }} />
      </Link>
    </Flex>
  );
};

export default Pagination;
